
.form-input {
    input {
        background: transparent;
        width: 100%;
        border: none;
        height: 100%;
        padding: var(--unit-1);
        border-radius: 13px;
        &:focus-visible {
            outline: none;
        }
    }
}
