
@import "../../../styles/variables";
@import "../../../styles/mixins";

.form-input__input-container {
    position: relative;
    background-color: transparent;
    border: 2px solid var(--primary-color);

    border-radius: 15px;
    font-weight: 600;
    width: 100%;

    &:focus-within {
        border: 2px solid var(--secondary-color);
        box-shadow: 0 0 8px var(--secondary-color);
    }

    .form-input__hint {
        font-size: 0.9rem;
    }
}
