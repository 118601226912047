@import "brand-variables";
@import "variables";
@import "reset";
@import "../node_modules/animate.css/animate.min.css";

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

:root {
    font-family: 'Inter', sans-serif;
    // Using "vw" allows for responsive text depending on the device width for increased readability.
    // This means that the narrower the device screen, the bigger the text.
    font-size: calc(16px + 0.01vw);
    line-height: var(--ratio);
    color: var(--text-color);
    background: var(--background);
}

* {
    color: inherit;
    transition: background-color 0.1s;
}

// Scroll bars

// Styling scroll bars
::-webkit-scrollbar {
    width: 20px;
}

::-webkit-scrollbar-track {
    background: none;
    background: var(--background-low);
}

::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: linear-gradient(180deg, var(--primary-color) 0%, rgba(220, 220, 220, 0) 173.91%);
}

