
@import "styles/style";
#app {
}

#nprogress .bar {
    background: var(--primary-color);
    height: 4px;
}

