
.badge {
    position: relative;
    padding: var(--unit-2);
    font-weight: 500;
    border-radius: 2.25rem;
    min-width: 4.5rem;
    text-align: center;
    background: var(--background-high);

    @each $i in 'primary', 'secondary', 'tertiary', 'info', 'success', 'warning', 'danger' {
        &.badge--#{$i} {
            color: var(--#{$i}-color-400);
            background: var(--#{$i}-color-10);
        }
    }
}
