
.center {
    display: block;
    box-sizing: content-box;
    margin: auto;
    margin-inline: auto;
    max-inline-size: var(line-length);

    // Ensure a minimum size on smaller screens, where the margins do not appear.
    padding-left: var(--unit-1);
    padding-right: var(--unit-1);

    &.center--content {
        display: flex;
        flex-direction: column;
        align-items: center;
     }
}
