:root {
    // Defines the longest comfortably readable length of content.
    // This property is used by different components to establish max-width properties.
    --line-length: 120ch;

    // UNIT SCALE
    // The unit scale defines a list of values that can be used for padding, margin and sizes for an harmonious
    // and consistent design. Its increments are based on a modular scale.
    // It contains a main size (based on the current font-size) known as unit0 and increases for four levels (1 to 4)
    // It also decreases below the unit0 for four levels (-1 to -4)
    --ratio: 1.61803398875; // Golden ratio

    --unit-4: calc(var(--unit-3) / var(--ratio));
    --unit-3: calc(var(--unit-2) / var(--ratio));
    --unit-2: calc(var(--unit-1) / var(--ratio));
    --unit-1: calc(var(--unit0) / var(--ratio));
    --unit0: 1rem;
    --unit1: calc(var(--unit0) * var(--ratio));
    --unit2: calc(var(--unit1) * var(--ratio));
    --unit3: calc(var(--unit2) * var(--ratio));
    --unit4: calc(var(--unit3) * var(--ratio));

    // The base border with of the different elements in the system.
    --border-width: 0px;
    // The base border radius for the different elements in the system.
    --border-radius: 20px;

    // CONTEXTUAL COLORS
    // Contextual colors allow the user to have a better understanding regarding the role of a certain UI element.
    // For instance primary buttons, use the primary color to indicate a main action, where secondary-colored buttons
    // indicate a action of lesser importance.
    // Colors have variations based on their brightness.
    // <context>-color-100 -> represents the base color without any brightness modifications
    // <context>-color-10 -> represents a color with 10% brightness
    // <context>-color-200 -> represents
    // you can use https://maketintsandshades.com/#c0392b to generate.

    --primary-color: var(--brand-green);
    --primary-color-10: var(--brand-green-10);
    --primary-color-50: var(--brand-green-50);
    --primary-color-100: var(--primary-color);
    --primary-color-200: var(--brand-green-200);
    --primary-color-300: var(--brand-green-300);
    --primary-color-400: var(--brand-green-400);

    --secondary-color: var(--brand-green);
    --secondary-color-10: var(--brand-green-10);
    --secondary-color-50: var(--brand-green-50);
    --secondary-color-100: var(--secondary-color);
    --secondary-color-200: var(--brand-green-200);
    --secondary-color-300: var(--brand-green-300);
    --secondary-color-400: var(--brand-green-400);

    // used in an informational context.
    --info-color: var(--brand-green);
    --info-color-10: var(--brand-green-10);
    --info-color-50: var(--brand-green-50);
    --info-color-100: var(--info-color);
    --info-color-200: var(--brand-green-200);
    --info-color-300: var(--brand-green-300);
    --info-color-400: var(--brand-green-400);

    // used in the context where some element denotes a successful event.
    --success-color: var(--brand-green);
    --success-color-10: var(--brand-green-10);
    --success-color-50: var(--brand-green-50);
    --success-color-100: var(--success-color);
    --success-color-200: var(--brand-green-200);
    --success-color-300: var(--brand-green-300);
    --success-color-400: var(--brand-green-400);

    // used in the context where some element denotes a cautionary event.
    --warning-color: var(--brand-yellow);
    --warning-color-10: var(--brand-yellow-10);
    --warning-color-50: var(--brand-yellow-50);
    --warning-color-100: var(--warning-color);
    --warning-color-200: var(--brand-yellow-200);
    --warning-color-300: var(--brand-yellow-300);
    --warning-color-400: var(--brand-yellow-400);

    // used in the context where some element denotes a risky event.
    --danger-color: #c0392b;
    --danger-color-10: #ecc4bf;
    --danger-color-50: #cd6155;
    --danger-color-100: var(--danger-color);
    --danger-color-200: #9a2e22;
    --danger-color-300: #73221a;
    --danger-color-400: #4d1711;

    // Backgrounds are often stacked on top of each other to indicate a certain level of elevation relatively to the base background.
    // There are three levels of elevation above the base background, which are "low", "medium" and "high". In total this allows for 4
    // levels of elevation if we include the base background.
    --background: #E5F6E0; // base background.
    --background-low: #C6D1C5;
    --background-medium: linear-gradient(166.25deg, #D9DFD8 25.24%, rgba(236, 236, 236, 0) 147.21%);
    --background-high: linear-gradient(161.81deg, #C6D1C5 54.52%, #48C639 202.58%);

    // The base text color of the system.
    --text-color: var(--brand-green-400);
    // An alternative text color for use where contrast should be made with the base color.
    // Some use cases of this color can be subtitles.
    --text-color-alt: #a5aab8;

    --text-color-inverse: #ECECEC;
}


// RESPONSIVE BREAKPOINT SIZES
$size-xs: 480px !default;
$size-sm: 600px !default;
$size-md: 840px !default;
$size-lg: 960px !default;
$size-xl: 1280px !default;
$size-2x: 1440px !default;

// Zindex Layers
$z-index-min: −2147483648;
$z-index-0: 0;
$z-index-1: 100;
$z-index-2: 200;
$z-index-3: 300;
$z-index-4: 400;
$z-index-5: 500;
$z-index-6: 600;
$z-index-7: 700;
$z-index-8: 800;
$z-index-9: 900;
$z-index-max: 2147483647;