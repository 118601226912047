
.data-points-table {
  text-align: center;
  table-layout: auto;
  border-collapse: collapse;
  th {
    padding: var(--unit0);
    background: #FFF;
    border-radius: 38px;
  }
  td {
    height: 60px;
    border-bottom: 2px solid var(--primary-color-300);
  }

  tr.data-point:hover {
    background: var(--primary-color-10);
    cursor: pointer;

  }

  .data-point.data-point-selected {
    background: var(--background-low);
  }

  .data-source-selected {
    border-radius: 0;
  }

  .data-source-data {
    width: 650px;
    //height: 220px;
    text-align: left;
    background: #FFF;
  }

  .data-source-metadata {
    height: 220px;
    text-align: left;
    flex: 1;
  }
}
