
.stack {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    &:only-child {
        height: 100%;
    }


    // SPACE
    @for $i from -1 to 5 {
        &.stack--space-unit#{$i} {
            gap: var(--unit#{$i});
        }
    }

    // JUSTIFY
    @each $i in 'space-between', 'space-around', 'space-evenly', 'start', 'end', 'center' {
        &.stack--justify-#{$i} {
            justify-content: #{$i};
        }
    }
}
