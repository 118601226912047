
$sidebar-width: 475px;
$navbar-height: 85px;
.sidebar {
  position: fixed;
  width: $sidebar-width;
  overflow-y: scroll;
  overflow-x: hidden;
  height: calc(100% - $navbar-height);
  border-radius: 0;
  & > * {
    border-radius: 0;
    padding-bottom: var(--unit3);
  }
}

.content-side {
  margin-left: $sidebar-width;
  padding-bottom: var(--unit3);
}
