
.cluster {
    display: flex;
    flex-wrap: wrap;
    
    // SPACE
    @for $i from -4 to 5 {
        &.cluster--space-unit#{$i} {
            gap: var(--unit#{$i});
        }
    }

    // JUSTIFY
    @each $i in 'space-between', 'space-around', 'space-evenly', 'start', 'end', 'center' {
         &.cluster--justify-#{$i} {
            justify-content: #{$i};
        }
    }
}
