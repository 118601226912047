
.icon {
    display: inline-block;
    margin-right: var(--unit-2);
    &.icon--no-margin {
        margin: 0;
    }

    // SIZE
    @for $i from -1 to 5 {
        &.icon--size#{$i} {
            width: var(--unit#{$i});
            height: var(--unit#{$i});
        }
    }
}
