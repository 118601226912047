
.label {
    position: relative;
    padding-left: var(--unit-1);
    &::before {
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        content: " ";
        width: 0.5rem;
        height: 80%;
        border-radius: 5px;
    }

    @each $i in 'primary', 'secondary', 'tertiary', 'info', 'success', 'warning', 'danger' {
        &.label--#{$i} {
            &::before {
                background: var(--#{$i}-color-100);
            }
        }
    }
}
