
.grid {
    display: grid;
    grid-gap: var(--unit1);
}

.grid > * {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 30ch;
}
