
@import "../../../styles/variables";
@import "../../../styles/mixins";

.btn {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--background-low);

    border-radius: 30px;
    border: none;
    padding: var(--unit-1) var(--unit-1);
    cursor: pointer;
    overflow: hidden;
    font-weight: 500;
    &:hover {
        filter: brightness(90%);
    }
    &:active {
        filter: brightness(80%);
    }
    &[disabled] {
        cursor: not-allowed;
    }

    min-height: 45px;
}


.btn--link {
    background: transparent;
}


.btn--solid {
    color: #FFF;
    @each $i in 'primary', 'secondary', 'tertiary', 'info', 'success', 'warning', 'danger' {
        &.btn--#{$i} {
            background: var(--#{$i}-color) !important;
            &:hover {
                filter: none;
                background: var(--#{$i}-color-200) !important;;
            }
            &:active {
                filter: none;
                background: var(--#{$i}-color-300) !important;;
            }
            &[disabled] {
                filter: none;
                background: var(--#{$i}-color-300) !important;;
            }
        }
    }
}

.btn--gradient {
    @each $i in 'primary', 'secondary', 'tertiary', 'info', 'success', 'warning', 'danger' {
        &.btn--#{$i} {
            background: linear-gradient(164deg, var(--background-low) 65.42%, var(--#{$i}-color) 172.43%);
        }
    }
    &:hover {
        filter: brightness(90%);
    }
    &:active {
        filter: brightness(80%);
    }
    &[disabled] {
        cursor: not-allowed;
        filter: brightness(80%);
    }
}

.btn--outline {
    background: none;
    border-width: 2px;
    border-style: solid;

    @each $i in 'primary', 'secondary', 'tertiary', 'info', 'success', 'warning', 'danger' {
        &.btn--#{$i} {
            color: var(--#{$i}-color);
            border-color: var(--#{$i}-color);
            &:hover {
                filter: none;
                background: var(--#{$i}-color-10);
            }
            &:active {
                filter: none;
                background: var(--#{$i}-color-50);
            }
            &[disabled] {
                filter: none;
                background: var(--#{$i}-color-50);
            }
        }
    }

    &:hover {
        color: #FFFFFF;
    }
}

.btn--circle {
    border-radius: 50%;

    @for $i from -1 to 5 {
        &.btn--circle-unit#{$i} {
            border-radius: 50%;
            width: var(--unit#{$i});
            height: var(--unit#{$i});
        }
    }
}


.btn--cta {
    padding: var(--unit0);
}
