
$navbar-height: 85px;

.page-wrapper {
  height: 100vh;
  .navbar {
    position: fixed;
    width: 100%;
    height: $navbar-height;
    padding: var(--unit0);
  }
  .page {
    padding-top: $navbar-height; // navbar size
    height: calc(100vh - $navbar-height);
  }
}
