

.data-sources-list {
  background: var(--background-high);
  padding: var(--unit1);

  .data-source-item {
    background: var(--primary-color-400);
    color: #FFF;

    &:hover {
      background: var(--primary-color-50);
      cursor: pointer;
     }

     &.selected {
      border: 3px solid var(--primary-color-400);
      background: var(--primary-color-200);
      }
  }
}
