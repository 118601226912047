
@import "../../../styles/variables";
@import "../../../styles/mixins";

.form-select {
    position: relative;
    select {
        -webkit-appearance: none;
        -moz-appearance: none;

        padding: var(--unit-1);
        width: 100%;
        background: var(--primary-color-400);
        border-radius: 16px;
        border: none;
        &:focus-visible {
            outline: none;
        }
    }
    .icon {
        pointer-events: none;
        position: absolute;
        right: var(--unit0);
        top: 50%;
        transform: translateY(-50%);
    }
}
