
.loading-icon {
    display: inline-block;
    border: 3px solid transparent;
    border-top-color: var(--text-color-alt);
    border-bottom-color: var(--text-color-alt);
    width: var(--unit1);
    height: var(--unit1);
    border-radius: 50%;
    animation: rotate 0.5s infinite ease-in-out;

    @each $i in 'primary', 'secondary', 'tertiary', 'info', 'success', 'warning', 'danger' {
        &.loading-icon--#{$i} {
            border-top-color: var(--#{$i}-color);
            border-bottom-color: var(--#{$i}-color);
        }
    }

    // SIZE
    @for $i from -1 to 5 {
        &.loading-icon--size#{$i} {
            width: var(--unit#{$i});
            height: var(--unit#{$i});
        }
    }
}


@keyframes rotate {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
