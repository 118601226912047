
.box {
    display: block;
    border: var(--border-width) solid;

    /* ↓ For high contrast mode */
    outline: var(--border-width) solid transparent;
    outline-offset: calc(var(--border-width) * -1);


    // This allows specifying the colors on the parent for easier variations.
    * {
        color: inherit;
    }


    /// STYLE
    border-radius: var(--border-radius);

    // TODO: generate using SASS
    &.box--bg-base {
        background: var(--background);
    }

    &.box--bg-low {
        background: var(--background-low);
    }

    &.box--bg-medium {
        background: var(--background-medium);
    }

    &.box--bg-high {
        background: var(--background-high);
    }

    @each $i in 'primary', 'secondary', 'tertiary', 'info', 'success', 'warning', 'danger' {
        &.box--bg-#{$i} {
            color: #FFFFFF;
            background: var(--#{$i}-color);
        }
    }

    // TODO: generate using SASS
    &.box--padding-4 {
        padding: var(--unit-4);
    }
    &.box--padding-3 {
        padding: var(--unit-3);
    }
    &.box--padding-2 {
        padding: var(--unit-2);
    }
    &.box--padding-1 {
        padding: var(--unit-1);
    }
    &.box--padding0 {
        padding: var(--unit0);
    }
    &.box--padding1 {
        padding: var(--unit1);
    }
    &.box--padding2 {
        padding: var(--unit2);
    }
    &.box--padding3 {
        padding: var(--unit3);
    }
    &.box--padding4 {
        padding: var(--unit4);
    }
}
