
.switcher {
    display: flex;
    flex-wrap: wrap;

    align-items: center;
    width: 100%;

    & > * {
        flex-grow: 1;
        // flex-basis: 1;
    }

    // JUSTIFY
    &.switcher--justify-space-between {
        justify-content: space-between;
    }
    &.switcher--justify-space-around {
        justify-content: space-around;
    }
    &.switcher--justify-space-evenly {
        justify-content: space-evenly;
    }
    &.switcher--justify-start {
        justify-content: start;
    }
    &.switcher--justify-end {
        justify-content: end;
    }
    &.switcher--justify-center {
        justify-content: center;
    }



    // SPACE
    &.switcher--space-unit-4 {
        gap: var(--unit-4);
    }
    &.switcher--space-unit-3 {
        gap: var(--unit-3);
    }
    &.switcher--space-unit-2 {
        gap: var(--unit-2);
    }
    &.switcher--space-unit-1 {
        gap: var(--unit-1);
    }
    &.switcher--space-unit0 {
        gap: var(--unit0);
    }
    &.switcher--space-unit1 {
        gap: var(--unit1);
    }
    &.switcher--space-unit2 {
        gap: var(--unit2);
    }
    &.switcher--space-unit3 {
        gap: var(--unit3);
    }
    &.switcher--space-unit4 {
        gap: var(--unit4);
    }
}
