
.login-page {
  background: url('../../assets/login_page_background.jpg');
}
.login-form {
  max-width: 650px;
  background: var(--brand-green-400);
  color: var(--text-color-inverse);
  padding: var(--unit3) var(--unit4);
}
.login-form__logo {
  width: 350px;
  height: auto;
  margin-bottom: var(--unit2);
}
