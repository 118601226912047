
.columns {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;

    &.columns--expand > * {
        flex-grow: 1;
    }


    &.columns--debug {
        border: 1px solid red;
        & > * {
            border: 1px solid chartreuse;
        }
    }

    // SPACE
    @for $i from -1 to 5 {
        &.columns--space-unit#{$i} {
            gap: calc(var(--unit#{$i}) / 2);
        }
    }

    // JUSTIFY
    @each $i in 'space-between', 'space-around', 'space-evenly', 'start', 'end', 'center' {
        &.columns--justify-#{$i} {
            justify-content: #{$i};
        }
    }

    // ALIGN
    @each $i in 'stretch', 'start', 'end', 'center' {
        &.columns--align-#{$i} {
            align-items: #{$i};
        }
    }
}
