
.navbar {
  border-radius: 0;
  z-index: 500;
  background: var(--brand-green-400);
  color: #E5F6E0;
  filter: drop-shadow(0 4px 3px rgb(0 0 0 / 0.07)) drop-shadow(0 2px 2px rgb(0 0 0 / 0.06));
  .logo {
    width: 250px;
    height: auto;
  }
}
