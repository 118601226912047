
.text {
    @each $i in 'primary', 'secondary', 'tertiary', 'info', 'success', 'warning', 'danger' {
        &.text--#{$i} {
            color: var(--#{$i}-color-200);
        }
    }

    @each $i in 'alt', 'inverse' {
        &.text--#{$i} {
            color: var(--text-color-#{$i});
        }
    }

    &.text--block {
        display: block;
    }

    &.text--strong {
        font-weight: 600;
    }
}

